import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSpecificEmployeeDataForWeek } from "../../../../../services/schedule.service";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import moment from "moment";
import { IWeeklySchedule } from "../../../../../types/interfaces/IWeeklySchedule";
import {
  IMonthDropDown,
  IWeekDropDown,
  IYearDropDown,
} from "../../../../../types/interfaces/IDateDropDown.interface";
interface NurseSchedule {
  date: string;
  floor: string;
  totalNurses: number;
  nurses: number;
  CN: number;
  patients: number;
  shift: string;
}

interface Props {
  month: IMonthDropDown;
  year: IYearDropDown;
  week: IWeekDropDown;
}
const NurseWeeklyActivities: React.FC<Props> = ({ month, year, week }) => {
  // const [weekDates, setWeekDates] = useState<{ day: string; date: string }[]>(
  //   []
  // );
  // useEffect(() => {
  //   const getCurrentWeekDates = () => {
  //     const today = new Date();
  //     const dayOfWeek = today.getDay();

  //     const startDate = new Date(today);
  //     startDate.setDate(today.getDate() - dayOfWeek);

  //     const weekDates = [];
  //     const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //     for (let i = 0; i < 7; i++) {
  //       const date = new Date(startDate);
  //       date.setDate(startDate.getDate() + i);
  //       weekDates.push({
  //         day: weekdays[date.getDay()],
  //         date: date.toISOString().split("T")[0],
  //       });
  //     }

  //     setWeekDates(weekDates);
  //   };
  //   getCurrentWeekDates();
  // }, []);

  // const nurseScheduleList = [
  //   {
  //     date: "2023-11-05",
  //     floor: "2nd",
  //     totalNurses: 7,
  //     nurses: 5,
  //     CN: 2,
  //     patients: 2,
  //     shift: "Day",
  //   },
  //   {
  //     date: "2023-11-06",
  //     floor: "2nd",
  //     totalNurses: 7,
  //     nurses: 5,
  //     CN: 2,
  //     patients: 2,
  //     shift: "Night",
  //   },
  //   {
  //     date: "2023-11-07",
  //     floor: "2nd",
  //     totalNurses: 7,
  //     nurses: 5,
  //     CN: 2,
  //     patients: 2,
  //     shift: "Day",
  //   },
  //   {
  //     date: "2023-11-08",
  //     floor: "2nd",
  //     totalNurses: 7,
  //     nurses: 5,
  //     CN: 2,
  //     patients: 2,
  //     shift: "Night",
  //   },
  // ];

  // const requestOffList: string[] = ["2023-08-03"];
  // const appliedLeaveList: string[] = ["2023-08-05"];

  // const hasRequestOffForDate = (date: string): boolean => {
  //   return requestOffList.includes(date);
  // };

  // const hasAppliedLeaveForDate = (date: string): boolean => {
  //   return appliedLeaveList.includes(date);
  // };

  // const getScheduleForDate = (
  //   date: string
  // ):
  //   | NurseSchedule
  //   | { date: string; requestOff?: boolean; appliedLeave?: boolean }
  //   | null
  //   | any => {
  //   const schedule = nurseScheduleList.find(
  //     (schedule) => schedule.date === date
  //   );
  //   if (schedule) {
  //     return schedule;
  //   } else if (hasRequestOffForDate(date)) {
  //     return { date: date, requestOff: true };
  //   } else if (hasAppliedLeaveForDate(date)) {
  //     return { date: date, appliedLeave: true };
  //   } else {
  //     return null;
  //   }
  // };

  ////////////////////////////////////

  const { selectedShift, date } = useSelector((state: RootState) => state.time);
  const [employeeWeeklySchedule, setEmployeeWeeklySchedule] =
    useState<IWeeklySchedule>(); // assign type
  // const weekNumber = date?.week; // Week number you want to get
  // const year = year?.year; // Year of the month
  // const month = month?.label; // Month (January is 1, February is 2, ..., December is 12)
  const shift =
    selectedShift === "sun"
      ? "day"
      : selectedShift === "moon"
      ? "night"
      : selectedShift;

  const getWeekDatesAndDayNames = (
    weekNumber: number,
    year: number,
    month: number
  ) => {
    const startDate = moment()
      .year(year)
      .month(month - 1)
      .date(1);
    // startDate.isoWeekday(1); // Setting to the first day of the week (Monday)
    startDate.isoWeekday(); //setting the first date of the month to the frst day of the week
    // Adjust to the specified week
    startDate.add(weekNumber - 1, "weeks");

    const weekDates = [];
    const dayNames = [];
    const daysInMonth = startDate.daysInMonth();

    for (let i = 0; i < 7; i++) {
      if (startDate.date() <= daysInMonth) {
        weekDates.push(startDate.clone());
        dayNames.push(startDate.format("ddd")); // Full day name (e.g., "Sunday")
      }
      startDate.add(1, "day");
    }

    return { weekDates, dayNames };
  };

  const { weekDates, dayNames } = getWeekDatesAndDayNames(
    +week.label,
    +year?.year,
    +month?.label
  );

  const fetchEmployeeWeeklyData = async () => {
    const res = await getSpecificEmployeeDataForWeek(
      shift,
      +week?.label,
      +month?.label,
      +year?.year
    );
    if (res?.res) {
      setEmployeeWeeklySchedule(res?.res as IWeeklySchedule);
    }
  };
  useEffect(() => {
    fetchEmployeeWeeklyData();
    // getWeekDatesAndDayNames(weekNumber, year, month);
  }, [selectedShift, month, year, week]);

  return (
    <>
      <div className="flex flex-1">
        {weekDates?.map((date, index: number) => (
          <div
            key={index}
            className={`basis-1/7 flex flex-col items-center justify-center flex-1`}
          >
            <div
              className={`h-[65px] py-[8px] px-[16px] border-r border-b border-beerus w-full 
              
              ${
                employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                  ?.isOnTimeOff
                  ? "bg-teal-50"
                  : // : getScheduleForDate(date)?.appliedLeave
                    // ? "bg-zinc-200"
                    ""
              }
              `}
            >
              <Link
                to="#"
                className={`text-sm font-semibold flex flex-col w-full items-start uppercase
                ${
                  employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isOnTimeOff
                    ? "text-teal-400"
                    : //  : getScheduleForDate(date)?.appliedLeave
                      //  ? "text-raditz"
                      "text-gray-600"
                }
                   
                `}
              >
                {dayNames[index]}{" "}
                <span
                  className={` 
                  ${
                    employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                      ?.isOnTimeOff
                      ? "text-teal-400"
                      : // : getScheduleForDate(date)?.appliedLeave
                        // ? "text-raditz"
                        "text-blackcst"
                  }
                  text-moon-24`}
                >
                  {date.format("DD")}
                </span>
              </Link>
            </div>
            <div
              className={`py-4 px-3 w-full border-r border-beerus flex-1 flex flex-col ${
                employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                  ?.isOnTimeOff
                  ? "bg-teal-50"
                  : // : getScheduleForDate(date)?.appliedLeave
                    // ? "bg-zinc-200"
                    ""
              }`}
            >
              {employeeWeeklySchedule?.employeeSchedule?.shifts.length !== 0 ? (
                employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                  ?.isOnTimeOff ? (
                  <div className="flex flex-col mb-2 flex-1 justify-center text-center">
                    <span className="text-teal-400">RO</span>
                  </div>
                ) : employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isOnLeave ? (
                  <div className="flex flex-col mb-2 flex-1 justify-center text-center">
                    <span className="text-raditz">AL</span>
                  </div>
                ) : employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isDayShift ||
                  employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isMidNightShift ||
                  employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isMidDayShift ||
                  employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                    ?.isNightShift ? (
                  <ul key={index}>
                    {/* <li className="flex flex-col mb-2">
                        <span className="text-moon-16 text-gray-600">Floor:</span>
                        <h4 className="font-semibold text-moon-16 text-blackcst">
                          {getScheduleForDate(date)?.floor}
                        </h4>
                      </li> */}
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">Shift:</span>
                      <div className="flex">
                        <div className="flex-1">
                          {employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.isDayShift ? (
                            <span className="inline-flex items-center justify-center w-6 h-6 text-krillin bg-chichi-10 text-moon-14 rounded-md">
                              <Icon icon="uil:sun" />
                            </span>
                          ) : employeeWeeklySchedule?.employeeSchedule?.shifts[
                              index
                            ]?.isNightShift ? (
                            <span className="inline-flex items-center justify-center w-6 h-6 bg-beerus  text-moon-14 rounded-md">
                              <Icon icon="uil:moon" />
                            </span>
                          ) : employeeWeeklySchedule?.employeeSchedule?.shifts[
                              index
                            ]?.isMidDayShift ? (
                            <span className="inline-flex items-center justify-center w-6 h-6 bg-beerus  text-moon-14 rounded-md">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M18.1 9.32L19.65 6l-4.15-.36c.6.51 1.13 1.14 1.55 1.86c.32.55.53 1.13.68 1.72C17.18 9.08 16.6 9 16 9h-.42A5.01 5.01 0 0 0 11 6c-2.76 0-5 2.24-5 5a5.01 5.01 0 0 0 3 4.58V16c0 3.87 3.13 7 7 7s7-3.13 7-7c0-3.13-2.06-5.79-4.9-6.68M8 11c0-1.65 1.35-3 3-3c1.08 0 2.03.58 2.56 1.45c-1.9.7-3.41 2.21-4.11 4.11A3 3 0 0 1 8 11m8 10c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m.5-4.75l2.86 1.69l-.75 1.22L15 17v-5h1.5zM11 4c-.84 0-1.65.15-2.39.42L11 1l2.39 3.42C12.65 4.15 11.84 4 11 4M4.95 14.5c.42.74.96 1.36 1.55 1.87L2.36 16l1.76-3.77c.14.77.41 1.55.83 2.27m-.84-4.71L2.34 6l4.16-.35A7.2 7.2 0 0 0 4.94 7.5c-.44.74-.69 1.5-.83 2.29"
                                />
                              </svg>
                            </span>
                          ) : employeeWeeklySchedule?.employeeSchedule?.shifts[
                              index
                            ]?.isMidNightShift ? (
                            <span className="inline-flex items-center justify-center w-6 h-6 bg-beerus  text-moon-14 rounded-md">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 56 56"
                              >
                                <path
                                  fill="currentColor"
                                  d="M38.524 11.125H33.18v-.117l5.46-7.195c.493-.634.68-1.032.68-1.454c0-.726-.562-1.171-1.336-1.171h-7.523c-.703 0-1.242.469-1.242 1.195c0 .773.539 1.219 1.242 1.219H35.5v.117l-5.531 7.172c-.492.656-.68.984-.68 1.476c0 .68.54 1.195 1.313 1.195h7.921c.704 0 1.22-.468 1.22-1.242c0-.703-.516-1.195-1.22-1.195m10.968 8.977H45.72v-.094l3.89-5.086c.445-.61.633-.961.633-1.383c0-.68-.539-1.125-1.265-1.125h-5.743c-.656 0-1.148.469-1.148 1.149c0 .726.492 1.171 1.148 1.171h3.399v.094l-3.867 5.063c-.446.562-.61.937-.61 1.406c0 .633.492 1.125 1.22 1.125h6.116c.68 0 1.149-.445 1.149-1.172c0-.68-.469-1.148-1.149-1.148m-23.648 34.71c8.578 0 15.515-4.312 18.68-11.648c.421-1.008.28-1.781-.165-2.25c-.422-.398-1.125-.492-1.968-.164c-1.758.703-3.914 1.102-6.61 1.102c-10.476 0-17.226-6.54-17.226-16.829c0-2.835.539-5.648 1.265-7.125c.47-.937.422-1.734.024-2.226c-.446-.516-1.242-.68-2.344-.235c-7.195 2.93-12.14 10.43-12.14 19.196c0 11.414 8.39 20.18 20.484 20.18m.047-3.562c-10.008 0-16.97-7.29-16.97-16.898c0-5.907 2.743-11.11 7.102-14.438c-.562 1.523-.89 3.867-.89 6.117c0 11.532 7.969 19.266 19.758 19.266c2.109 0 4.03-.258 5.015-.61c-2.93 4.055-8.156 6.563-14.015 6.563m13.265-23.133h-3.21v-.094l3.304-4.382c.422-.61.61-.914.61-1.313c0-.68-.516-1.078-1.196-1.078h-5.062c-.633 0-1.102.445-1.102 1.102c0 .68.469 1.101 1.102 1.101h2.812v.094l-3.258 4.36c-.422.562-.586.89-.586 1.335c0 .61.47 1.055 1.149 1.055h5.437c.633 0 1.078-.422 1.078-1.102c0-.656-.445-1.078-1.078-1.078"
                                />
                              </svg>
                            </span>
                          ) : null}
                        </div>

                        {employeeWeeklySchedule?.employeeSchedule?.shifts[index]
                          ?.shiftStartTime &&
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.shiftEndTime && (
                            <div className="flex gap-2 items-center justify-between text-[12px] text-gray-600">
                              <span>
                                <strong>
                                  {
                                    employeeWeeklySchedule?.employeeSchedule
                                      ?.shifts[index]?.shiftStartTime
                                  }
                                </strong>
                              </span>{" "}
                              -
                              <span>
                                <strong>
                                  {
                                    employeeWeeklySchedule?.employeeSchedule
                                      ?.shifts[index]?.shiftEndTime
                                  }
                                </strong>
                              </span>
                            </div>
                          )}
                      </div>
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">
                        Total Staff:
                      </span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalEmployees
                        }
                      </h4>
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">
                        Nurses:
                      </span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalNurses
                        }
                      </h4>
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">
                        Senior Nurses:
                      </span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalSeniorNurses
                        }
                      </h4>
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">HCA:</span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalHCAs
                        }
                      </h4>
                    </li>
                    <li className="flex flex-col mb-2">
                      <span className="text-moon-16 text-gray-600">
                        Patients:
                      </span>
                      <h4 className="font-semibold text-moon-16 text-blackcst">
                        {
                          employeeWeeklySchedule?.employeeSchedule?.shifts[
                            index
                          ]?.totalPatients
                        }
                      </h4>
                    </li>
                  </ul>
                ) : (
                  "NA"
                )
              ) : (
                <div
                  className="flex flex-col mb-2 flex-1 justify-center text-center"
                  key={index}
                >
                  <span className="opacity-30">No schedule</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NurseWeeklyActivities;
