import React, { useEffect } from "react";
import { OtherMoon } from "@heathmont/moon-icons-tw";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedShift } from "../../../../../features/Time Slice/timeSlice";
import { RootState } from "../../../../../store/store";
import { useLocation } from "react-router-dom";
const FilterShifts = () => {
  // const [selectedButton, setSzelectedButton] = useState("all");
  const { selectedShift } = useSelector((state: RootState) => state.time);
  const dispatch = useDispatch();
  const selectedCalendar = useSelector(
    (state: RootState) => state.time.selectedCalendar
  );
  const handleButtonClick = (button: string) => {
    dispatch(setSelectedShift(button));
  };

  const location = useLocation();

  // useEffect(() => {
  //   if (selectedShift !== "all") {
  //     dispatch(setSelectedShift("sun"));
  //   }
  // }, []);
  return (
    <>
      {" "}
      {selectedCalendar === "month" && (
        <div className="flex items-center justify-center gap-1 p-1 bg-goku rounded-moon-s-sm min-h-[42px]">
          {location.pathname === "/monthly" ||
          location.pathname === "/scheduler" ? null : (
            <button
              className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-14 font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
                selectedShift === "All"
                  ? "bg-gohan text-slate-900"
                  : "text-trunks"
              }`}
              onClick={() => handleButtonClick("All")}
            >
              All
            </button>
          )}
          <button
            className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-24  font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
              selectedShift === "sun" ? "bg-gohan text-krillin " : "text-trunks"
            }`}
            onClick={() => handleButtonClick("sun")}
          >
            <Icon icon="uil:sun" />
          </button>
          <button
            className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-24 font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
              selectedShift === "moon"
                ? "bg-gohan text-slate-900"
                : "text-trunks"
            }`}
            onClick={() => handleButtonClick("moon")}
          >
            <OtherMoon />
          </button>
          <button
            title="Mid Day"
            className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-24 font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
              selectedShift === "mid-day"
                ? "bg-gohan opacity-100"
                : "opacity-50"
            }`}
            onClick={() => handleButtonClick("mid-day")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M18.1 9.32L19.65 6l-4.15-.36c.6.51 1.13 1.14 1.55 1.86c.32.55.53 1.13.68 1.72C17.18 9.08 16.6 9 16 9h-.42A5.01 5.01 0 0 0 11 6c-2.76 0-5 2.24-5 5a5.01 5.01 0 0 0 3 4.58V16c0 3.87 3.13 7 7 7s7-3.13 7-7c0-3.13-2.06-5.79-4.9-6.68M8 11c0-1.65 1.35-3 3-3c1.08 0 2.03.58 2.56 1.45c-1.9.7-3.41 2.21-4.11 4.11A3 3 0 0 1 8 11m8 10c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m.5-4.75l2.86 1.69l-.75 1.22L15 17v-5h1.5zM11 4c-.84 0-1.65.15-2.39.42L11 1l2.39 3.42C12.65 4.15 11.84 4 11 4M4.95 14.5c.42.74.96 1.36 1.55 1.87L2.36 16l1.76-3.77c.14.77.41 1.55.83 2.27m-.84-4.71L2.34 6l4.16-.35A7.2 7.2 0 0 0 4.94 7.5c-.44.74-.69 1.5-.83 2.29"
              />
            </svg>
          </button>
          <button
            title="Mid Night"
            className={`px-3 py-1 flex items-center justify-center w-full whitespace-nowrap text-moon-24 font-medium rounded-moon-i-sm transition-colors cursor-pointer hover:bg-gohan focus:outline-none ${
              selectedShift === "mid-night"
                ? "bg-gohan opacity-100"
                : "opacity-50"
            }`}
            onClick={() => handleButtonClick("mid-night")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 56 56"
            >
              <path
                fill="currentColor"
                d="M38.524 11.125H33.18v-.117l5.46-7.195c.493-.634.68-1.032.68-1.454c0-.726-.562-1.171-1.336-1.171h-7.523c-.703 0-1.242.469-1.242 1.195c0 .773.539 1.219 1.242 1.219H35.5v.117l-5.531 7.172c-.492.656-.68.984-.68 1.476c0 .68.54 1.195 1.313 1.195h7.921c.704 0 1.22-.468 1.22-1.242c0-.703-.516-1.195-1.22-1.195m10.968 8.977H45.72v-.094l3.89-5.086c.445-.61.633-.961.633-1.383c0-.68-.539-1.125-1.265-1.125h-5.743c-.656 0-1.148.469-1.148 1.149c0 .726.492 1.171 1.148 1.171h3.399v.094l-3.867 5.063c-.446.562-.61.937-.61 1.406c0 .633.492 1.125 1.22 1.125h6.116c.68 0 1.149-.445 1.149-1.172c0-.68-.469-1.148-1.149-1.148m-23.648 34.71c8.578 0 15.515-4.312 18.68-11.648c.421-1.008.28-1.781-.165-2.25c-.422-.398-1.125-.492-1.968-.164c-1.758.703-3.914 1.102-6.61 1.102c-10.476 0-17.226-6.54-17.226-16.829c0-2.835.539-5.648 1.265-7.125c.47-.937.422-1.734.024-2.226c-.446-.516-1.242-.68-2.344-.235c-7.195 2.93-12.14 10.43-12.14 19.196c0 11.414 8.39 20.18 20.484 20.18m.047-3.562c-10.008 0-16.97-7.29-16.97-16.898c0-5.907 2.743-11.11 7.102-14.438c-.562 1.523-.89 3.867-.89 6.117c0 11.532 7.969 19.266 19.758 19.266c2.109 0 4.03-.258 5.015-.61c-2.93 4.055-8.156 6.563-14.015 6.563m13.265-23.133h-3.21v-.094l3.304-4.382c.422-.61.61-.914.61-1.313c0-.68-.516-1.078-1.196-1.078h-5.062c-.633 0-1.102.445-1.102 1.102c0 .68.469 1.101 1.102 1.101h2.812v.094l-3.258 4.36c-.422.562-.586.89-.586 1.335c0 .61.47 1.055 1.149 1.055h5.437c.633 0 1.078-.422 1.078-1.102c0-.656-.445-1.078-1.078-1.078"
              />
            </svg>
          </button>
        </div>
      )}
    </>
  );
};

export default FilterShifts;
