import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ScheduleNurseDrawer from "../drawer/schedule-drawer";
import { Icon } from "@iconify/react";
import axios from "axios";
import moment from "moment";
import {
  IEmployeeData,
  IEmployeeDataResponse,
  IShift,
} from "../../../../../types/interfaces/IMonthSchedule.interface";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../../../../store/store";
import { setMonthSchedule } from "../../../../../features/Schedule/Month/MonthScheduleSlice";
import { current } from "@reduxjs/toolkit";
import { ICalendarWeekday } from "../../../../../types/interfaces/IMonthCalendar.interface";
import {
  getDayInfo,
  getSpecificEmployeeDataForMonth,
} from "../../../../../services/schedule.service";
import { MonthlySchedulerProps } from "../../../../../types/interfaces/IMonthScheduleProps.interface";
import SimpleBar from "simplebar-react";
import WeekDaysTab from "../../../../../Components/Calendar/full-month-view/WeekDays";
import {
  IMonthDropDown,
  IYearDropDown,
} from "../../../../../types/interfaces/IDateDropDown.interface";
import { ISpecificDayRecord } from "../../../../../types/interfaces/ISpecificDayRecord";
import { IDayInfo } from "../../../../../types/interfaces/IDayInfo";
interface DayInfo {
  date: string; // Assuming date is in ISO format
  allocation: IShift;
  isCurrentMonth: boolean;
}

// interface SpecificDayRecord {
//   month: number;
//   monthName: string;
//   year: number;
//   scheduledBy: ScheduledBy;
//   dayNumber: number;
//   day: string;
//   dayShift: boolean;
//   nightShift: boolean;
//   status: string;
//   capacity: number;
//   utilization: string;
//   numberOfPatients: number;
//   criticalPatients: number;
//   employees: Employee[];
// }
// interface Employee {
//   employeeId: string;
//   employeeName: string;
//   designation: string;
//   shiftsSpent: number;
//   shiftsAllocated: number;
//   hoursSpent: number;
//   hoursAllocated: number;
// }
// interface ScheduledBy {
//   id: string;
//   firstName: string;
//   lastName: string;
// }
const weekDays = ["Sat", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri"];

interface Props {
  month: IMonthDropDown;
  year: IYearDropDown;
}
export const MonthCalender: React.FC<Props> = ({ month, year }) => {
  const { selectedShift } = useSelector((state: RootState) => state?.time);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [employeeData, setEmployeeData] = useState<IEmployeeData>(
    {} as IEmployeeData
  );
  const [specificDayRecord, setSpecificDayRecord] = useState<IDayInfo>();

  const fetchDayInfo = async (day: number) => {
    const res = await getDayInfo(day, +month.label, +year?.year);
    if (res?.res) {
      setSpecificDayRecord(res?.res as IDayInfo);
    }
  };
  const handleClick = (dayInfo: IShift) => {
    if (dayInfo?.isNightShift || dayInfo?.isDayShift) {
      fetchDayInfo(moment(dayInfo?.date).date());
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(!isOpen);
  };

  const fetchMonthlySchedule = async () => {
    const res: any = await getSpecificEmployeeDataForMonth(
      +month?.label,
      +year?.year
    );
    if (res?.res) {
      setEmployeeData(res?.res?.employeeInfo);
    }
  };

  // const startDate = 1; // Starting date of the month
  // const endDate = daysInCurrentMonth; // Ending date of the month

  // const startDayOffset =
  //   (new Date(currentYear, currentMonth, 1).getDay() + 1) % 7;
  // const endDayOffset =
  //   7 - ((new Date(currentYear, currentMonth, endDate).getDay() + 1) % 7);

  /**
   * Generates a calendar for the given year and month, with allocation data.
   *
   * @param year - The year of the calendar.
   * @param month - The month of the calendar (0-11).
   * @param allocationData - The allocation data for the calendar.
   * @returns The generated calendar.
   */
  const generateCalendar = (
    year: number,
    month: number,
    allocationData: IShift[]
  ) => {
    const calendar: any = [];

    // Get the first day of the month
    const firstDay = new Date(year, month, 1);

    // Get the last day of the month
    const lastDay = new Date(year, month + 1, 0);

    // Get the start day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
    let startDayOfWeek = firstDay.getDay();

    // Adjust startDayOfWeek to consider Monday as the first day
    startDayOfWeek = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1;

    let currentDate = new Date(firstDay);

    // Move back to the previous Monday if the month doesn't start on a Monday
    currentDate.setDate(currentDate.getDate() - startDayOfWeek);

    while (currentDate <= lastDay) {
      const week: any = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(currentDate);
        const isCurrentMonth = date.getMonth() === month;

        // Find allocation data for the current date
        const formattedDate = moment(date).format("YYYY-MM-DD");
        const allocation = allocationData?.find((data) => {
          if (moment(data.date).format("YYYY-MM-DD") === formattedDate) {
            return data;
          } else {
            return undefined;
          }
        });

        week.push({
          date,
          isCurrentMonth,
          allocation,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      calendar.push(week);
    }

    return calendar;
  };

  /**
   * Renders the dates of a calendar.
   *
   * @param calendar - The calendar to render.
   * @returns The rendered calendar dates.
   */
  // const renderMonthDates = (calendar: any): any => {

  //   return calendar.map((week: any) =>
  //     week.map((data: ICalendarWeekday, index: any) => (
  //       <div
  //         key={index}
  //         onClick={handleClick}
  //         className={`basis-1/7 p-[12px] border border-beerus h-[110px] py-2 text-[14px] border-r order-s-0 justify-center inline-flex items-center ${
  //           data?.allocation?.isOnLeave
  //             ? "bg-zinc-200 text-raditz"
  //             : data?.allocation?.isDayShift || data?.allocation?.isNightShift
  //             ? ""
  //             : data?.allocation?.isOnTimeOff
  //             ? "bg-teal-50 text-teal-400"
  //             : ""
  //         } ${data?.allocation?.dayNumber === 20 ? "highlighted" : ""} ${
  //           data.isCurrentMonth ? "" : "opacity-40"
  //         }`}
  //       >
  //         <div className="w-full h-full flex flex-col">
  //           <div className="flex items-center justify-between">
  //             {data?.allocation?.dayNumber
  //               ? data?.allocation.dayNumber
  //               : data.date.getDate()}{" "}
  //             {/* Convert date to string */}
  //             {data?.allocation?.isOnLeave ? (
  //               ""
  //             ) : data?.allocation?.isDayShift ? (
  //               <span className="inline-flex items-center justify-center w-6 h-6 text-krillin bg-chichi-10 text-moon-14 rounded-md">
  //                 <Icon icon="uil:sun" />
  //               </span>
  //             ) : data?.allocation?.isNightShift ? (
  //               <span className="inline-flex items-center justify-center w-6 h-6 bg-gray-100 text-moon-14 rounded-md">
  //                 {/* Replace this with the night shift icon */}
  //                 <Icon icon="uil:moon" />
  //               </span>
  //             ) : data?.allocation?.isOnTimeOff ? (
  //               ""
  //             ) : null}
  //           </div>
  //           <div className="text-center flex-1 w-full flex items-center justify-center pb-[21px] ">
  //             {data?.allocation?.isDayShift ||
  //             data?.allocation?.isNightShift ? (
  //               ""
  //             ) : data?.allocation?.isOnLeave ? (
  //               <span className="font-semibold">
  //                 {data?.allocation?.leaveType}
  //               </span>
  //             ) : data?.allocation?.isOnTimeOff ? (
  //               <span className="font-semibold">RO</span>
  //             ) : null}
  //           </div>
  //         </div>
  //       </div>
  //     )),
  //   );
  // };

  useEffect(() => {
    if (month || year) {
      fetchMonthlySchedule();
    }
  }, [month, year]);

  const calendarData = generateCalendar(
    +year?.year,
    +month?.label - 1,
    employeeData?.shifts
  );

  return (
    <>
      <>
        {/* <SimpleBar
        className="overflow-y-auto"
        style={{ height: "calc(100vh - 440px)" }}
      > */}
        {calendarData?.map((week: any, weekIndex: number) => (
          <div key={weekIndex} className="flex flex-wrap">
            {week?.map(
              (
                dayInfo: DayInfo,
                dayIndex: number // dayInfo==> allocation :{} //object //date//isCurrentMonth
              ) => (
                <div
                  key={dayIndex}
                  onClick={() => {
                    handleClick(dayInfo?.allocation);
                  }}
                  className={`basis-1/7 p-[12px] border border-beerus h-[110px] py-2 text-[14px] border-r order-s-0 justify-center inline-flex items-center ${
                    dayInfo?.allocation?.isOnLeave
                      ? "bg-zinc-200 text-raditz"
                      : dayInfo?.allocation?.isDayShift ||
                        dayInfo?.allocation?.isNightShift ||
                        dayInfo?.allocation?.isMidNightShift ||
                        dayInfo?.allocation?.isMidDayShift
                      ? ""
                      : dayInfo?.allocation?.isOnTimeOff
                      ? "bg-teal-50 text-teal-400"
                      : ""
                  } ${
                    dayInfo?.allocation?.dayNumber === 20 ? "highlighted" : ""
                  } ${dayInfo.isCurrentMonth ? "" : "opacity-40"}`}
                >
                  <div className="w-full h-full flex flex-col">
                    <div className="flex items-center justify-between">
                      {moment(dayInfo?.date).format("DD")}
                      {/* Convert date to string */}
                      {dayInfo?.allocation?.isOnLeave ? (
                        ""
                      ) : dayInfo?.allocation?.isDayShift ? (
                        <span
                          className={`inline-flex items-center justify-center w-6 h-6 text-krillin ${
                            selectedShift !== "moon" &&
                            selectedShift !== "mid-day" &&
                            selectedShift !== "mid-night" &&
                            "bg-krillin-10"
                          } text-moon-14 rounded-md`}
                        >
                          {selectedShift !== "moon" &&
                            selectedShift !== "mid-day" &&
                            selectedShift !== "mid-night" && (
                              <Icon icon="uil:sun" />
                            )}
                        </span>
                      ) : dayInfo?.allocation?.isNightShift ? (
                        <span
                          className={`inline-flex items-center justify-center w-6 h-6 ${
                            selectedShift !== "sun" &&
                            selectedShift !== "mid-day" &&
                            selectedShift !== "mid-night" &&
                            "bg-beerus"
                          }  text-moon-14 rounded-md`}
                        >
                          {/* Replace this with the night shift icon */}
                          {/* <Icon icon="uil:moon" /> */}
                          {selectedShift !== "sun" &&
                            selectedShift !== "mid-day" &&
                            selectedShift !== "mid-night" && (
                              <Icon icon="uil:moon" />
                            )}
                        </span>
                      ) : dayInfo?.allocation?.isMidNightShift ? (
                        <span
                          className={`inline-flex items-center justify-center w-6 h-6 ${
                            selectedShift !== "sun" &&
                            selectedShift !== "mid-day" &&
                            selectedShift !== "moon" &&
                            "bg-beerus"
                          }  text-moon-14 rounded-md`}
                        >
                          {/* Replace this with the night shift icon */}
                          {/* <Icon icon="uil:moon" /> */}
                          {selectedShift !== "sun" &&
                            selectedShift !== "mid-day" &&
                            selectedShift !== "moon" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 56 56"
                              >
                                <path
                                  fill="currentColor"
                                  d="M38.524 11.125H33.18v-.117l5.46-7.195c.493-.634.68-1.032.68-1.454c0-.726-.562-1.171-1.336-1.171h-7.523c-.703 0-1.242.469-1.242 1.195c0 .773.539 1.219 1.242 1.219H35.5v.117l-5.531 7.172c-.492.656-.68.984-.68 1.476c0 .68.54 1.195 1.313 1.195h7.921c.704 0 1.22-.468 1.22-1.242c0-.703-.516-1.195-1.22-1.195m10.968 8.977H45.72v-.094l3.89-5.086c.445-.61.633-.961.633-1.383c0-.68-.539-1.125-1.265-1.125h-5.743c-.656 0-1.148.469-1.148 1.149c0 .726.492 1.171 1.148 1.171h3.399v.094l-3.867 5.063c-.446.562-.61.937-.61 1.406c0 .633.492 1.125 1.22 1.125h6.116c.68 0 1.149-.445 1.149-1.172c0-.68-.469-1.148-1.149-1.148m-23.648 34.71c8.578 0 15.515-4.312 18.68-11.648c.421-1.008.28-1.781-.165-2.25c-.422-.398-1.125-.492-1.968-.164c-1.758.703-3.914 1.102-6.61 1.102c-10.476 0-17.226-6.54-17.226-16.829c0-2.835.539-5.648 1.265-7.125c.47-.937.422-1.734.024-2.226c-.446-.516-1.242-.68-2.344-.235c-7.195 2.93-12.14 10.43-12.14 19.196c0 11.414 8.39 20.18 20.484 20.18m.047-3.562c-10.008 0-16.97-7.29-16.97-16.898c0-5.907 2.743-11.11 7.102-14.438c-.562 1.523-.89 3.867-.89 6.117c0 11.532 7.969 19.266 19.758 19.266c2.109 0 4.03-.258 5.015-.61c-2.93 4.055-8.156 6.563-14.015 6.563m13.265-23.133h-3.21v-.094l3.304-4.382c.422-.61.61-.914.61-1.313c0-.68-.516-1.078-1.196-1.078h-5.062c-.633 0-1.102.445-1.102 1.102c0 .68.469 1.101 1.102 1.101h2.812v.094l-3.258 4.36c-.422.562-.586.89-.586 1.335c0 .61.47 1.055 1.149 1.055h5.437c.633 0 1.078-.422 1.078-1.102c0-.656-.445-1.078-1.078-1.078"
                                />
                              </svg>
                            )}
                        </span>
                      ) : dayInfo?.allocation?.isMidDayShift ? (
                        <span
                          className={`inline-flex items-center justify-center w-6 h-6 ${
                            selectedShift !== "sun" &&
                            selectedShift !== "moon" &&
                            selectedShift !== "mid-night" &&
                            "bg-krillin-10"
                          }  text-moon-14 rounded-md`}
                        >
                          {/* Replace this with the night shift icon */}
                          {/* <Icon icon="uil:moon" /> */}
                          {selectedShift !== "sun" &&
                            selectedShift !== "moon" &&
                            selectedShift !== "mid-night" && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  fill="currentColor"
                                  d="M18.1 9.32L19.65 6l-4.15-.36c.6.51 1.13 1.14 1.55 1.86c.32.55.53 1.13.68 1.72C17.18 9.08 16.6 9 16 9h-.42A5.01 5.01 0 0 0 11 6c-2.76 0-5 2.24-5 5a5.01 5.01 0 0 0 3 4.58V16c0 3.87 3.13 7 7 7s7-3.13 7-7c0-3.13-2.06-5.79-4.9-6.68M8 11c0-1.65 1.35-3 3-3c1.08 0 2.03.58 2.56 1.45c-1.9.7-3.41 2.21-4.11 4.11A3 3 0 0 1 8 11m8 10c-2.76 0-5-2.24-5-5s2.24-5 5-5s5 2.24 5 5s-2.24 5-5 5m.5-4.75l2.86 1.69l-.75 1.22L15 17v-5h1.5zM11 4c-.84 0-1.65.15-2.39.42L11 1l2.39 3.42C12.65 4.15 11.84 4 11 4M4.95 14.5c.42.74.96 1.36 1.55 1.87L2.36 16l1.76-3.77c.14.77.41 1.55.83 2.27m-.84-4.71L2.34 6l4.16-.35A7.2 7.2 0 0 0 4.94 7.5c-.44.74-.69 1.5-.83 2.29"
                                />
                              </svg>
                            )}
                        </span>
                      ) : dayInfo?.allocation?.isOnTimeOff ? (
                        ""
                      ) : null}
                    </div>
                    <div className="text-center flex-1 w-full flex items-center justify-center pb-[21px] ">
                      {dayInfo?.allocation?.isDayShift ||
                      dayInfo?.allocation?.isNightShift ||
                      dayInfo?.allocation?.isMidDayShift ||
                      dayInfo?.allocation?.isMidNightShift ? (
                        ""
                      ) : dayInfo?.allocation?.isOnLeave ? (
                        <span className="font-semibold">
                          {dayInfo?.allocation?.leaveType}
                        </span>
                      ) : dayInfo?.allocation?.isOnTimeOff ? (
                        <span className="font-semibold">RO</span>
                      ) : null}
                    </div>
                    {!dayInfo?.allocation?.isOnLeave &&
                      !dayInfo?.allocation?.isOnTimeOff &&
                      dayInfo?.allocation?.shiftStartTime &&
                      dayInfo?.allocation?.shiftEndTime && (
                        <>
                          {((selectedShift === "sun" &&
                            dayInfo?.allocation?.isDayShift) ||
                            (selectedShift === "moon" &&
                              dayInfo?.allocation?.isNightShift) ||
                            (selectedShift === "mid-day" &&
                              dayInfo?.allocation?.isMidDayShift) ||
                            (selectedShift === "mid-night" &&
                              dayInfo?.allocation?.isMidNightShift) ||
                            selectedShift === "All") && (
                            <div className="flex items-center justify-between text-[12px]">
                              <span>
                                Start Shift: <br />{" "}
                                <strong>
                                  {dayInfo.allocation.shiftStartTime}
                                </strong>
                              </span>
                              <span>
                                End Shift: <br />{" "}
                                <strong>
                                  {dayInfo.allocation.shiftEndTime}
                                </strong>
                              </span>
                            </div>
                          )}
                        </>
                      )}
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </>
      {/* </SimpleBar> */}
      <ScheduleNurseDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleClose={handleClose}
        data={specificDayRecord}
      />
    </>
  );
};
